var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import { PageAppear } from '../../twig/components/PageAppear';
function filterArchive() {
    $('.letter, .item').removeClass('d-none');
    $('.dropdown').forEach((item, index) => {
        var parameter = $(item).data('parameter');
        var value = $(item).find('.selected').data('value');
        if (value == 'all')
            return;
        $('.item').forEach((item) => {
            var itemParameter = $(item).data(parameter).toString().split(',');
            if (itemParameter.indexOf(value) == -1)
                $(item).addClass('d-none');
        });
    });
    $('.letter').forEach((item, index) => {
        if ($(item).find('.item:not(.d-none').length == 0)
            $(item).addClass('d-none');
    });
    PageAppear.scanContent();
    PageAppear.appear();
}
export class DropdownComponent extends Component {
    constructor() {
        super('DropdownComponent', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('.dropdown:not(.dropdown-sm) .button, .dropdown:not(.dropdown-sm) nav').forEach((item) => {
                $(item).prepend($.parseHTML('<span class="corner corner-1"></span><span class="corner corner-2"></span><span class="corner corner-3"></span><span class="corner corner-4"></span>'));
            });
            $('.dropdown .button').forEach((item) => {
                $(item).prepend($.parseHTML('<div class="arrow"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div>'));
            });
            $('body').on('click', function (event) {
                $('.dropdown .button').next().removeClass('active');
            });
            $('.dropdown .button').on('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                var isCurrentlyOpened = $(this).next().hasClass('active');
                $('.dropdown .button').next().removeClass('active');
                if (!isCurrentlyOpened) {
                    $(this).next().addClass('active');
                }
            });
            $('.dropdown nav a').on('click', (event) => {
                event.preventDefault();
                var parent = $(event.target).parents('.dropdown');
                parent.find('nav').removeClass('active');
                parent.find('nav a').removeClass('selected');
                $(event.target).addClass('selected');
                var value = $(event.target).data('value');
                var label = $(event.target).text();
                parent.find('.button em').text((value == 'all') ? parent.data('default') : label);
                if (parent.hasClass('dropdown-sm')) {
                    parent.next().get(0).focus();
                }
                else {
                    filterArchive();
                }
            });
            this.success();
        });
    }
}
