var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Collection } from 'hanako-ts/dist-legacy/Collection';
import { Component } from 'hanako-ts/dist-legacy/Component';
import { InitalLetter } from './Appearers/InitialLetters';
import { Typewriter } from './Appearers/Typewriter';
import { Pixelator } from './Appearers/Pixelator';
function isInViewport(item) {
    const rect = item.getBoundingClientRect();
    return (rect.top >= 0 && rect.top < (window.innerHeight || document.documentElement.clientHeight)) || (rect.bottom >= 0 && rect.bottom < (window.innerHeight || document.documentElement.clientHeight));
}
export class PageAppear extends Component {
    constructor() {
        super('PageAppear', true);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            //Titles
            $('.title').forEach((item) => {
                var text = $(item).text().toLowerCase();
                $(item).text('');
                $(item).empty();
                for (var i = 0; i < text.length; i++) {
                    $(item).append($.parseHTML('<ltr class="appear-element initial-letter appear-only-once">' + text[i] + '</ltr>'));
                }
            });
            // If already on site
            const alreadyIn = (sessionStorage.getItem('already-in') == 'true') ? true : false;
            if (alreadyIn) {
                $('.appear-only-once').forEach((item) => {
                    if ($(item).hasClass('initial-letter')) {
                        new InitalLetter(item, true);
                    }
                    else {
                        $(item).removeClass('appeareable').addClass('appeared');
                    }
                });
                setTimeout(function () {
                    $('.appear-only-once').forEach((item) => {
                        $(item).removeClass('appear-only-once');
                    });
                }, 500);
            }
            $('#button-toggle-menu').removeClass('d-none');
            //Sub elements
            $('.splitable').forEach((item) => {
                $(item).find('h2, h3, p, li').forEach((child) => {
                    $(child).addClass('appeareable');
                });
                $(item).removeClass('splitable');
            });
            $(window).on('scroll', () => {
                PageAppear.scanContent();
                if (!PageAppear.scrollTimeout) {
                    PageAppear.scrollTimeout = window.setTimeout(() => {
                        PageAppear.scrollTimeout = null;
                        PageAppear.appear();
                        PageAppear.itemsIndex++;
                    }, 100);
                }
            });
            this.success();
            PageAppear.start();
        });
    }
    static start() {
        $('.appeareable').forEach((item) => {
            if (isInViewport(item))
                $(item).addClass('appear-element');
            $(item).removeClass('appeareable');
        });
        PageAppear.scanContent();
        PageAppear.appear();
        PageAppear.itemsIndex++;
        sessionStorage.setItem('already-in', 'true');
    }
    static scanContent() {
        $('.appear-element').forEach((item) => {
            if ($(item).hasClass('scanned') || $(item).hasClass('appeared'))
                return;
            if (isInViewport(item)) {
                $(item).addClass('scanned');
                if (!PageAppear.items[PageAppear.itemsIndex])
                    PageAppear.items[PageAppear.itemsIndex] = new Collection();
                PageAppear.items[PageAppear.itemsIndex].add(item);
            }
            ;
        });
    }
    static appear(legacyItems) {
        var items = (!legacyItems) ? PageAppear.items[PageAppear.itemsIndex] : legacyItems;
        if (!items)
            return;
        var itemFound = false;
        items.forEach((item) => {
            if ($(item).hasClass('appearing') || $(item).hasClass('appeared') || itemFound)
                return;
            if (item.tagName == 'IMG') {
                PageAppear.appearImageNode(items, item);
            }
            else if ($(item).hasClass('initial-letter')) {
                PageAppear.appearInitialLetter(items, item);
            }
            else if ($(item).hasClass('appear-block')) {
                PageAppear.appearBlock(items, item);
            }
            else if ($(item).hasClass('dropdown') || $(item).hasClass('map') || item.tagName == 'input') {
                setTimeout(() => {
                    $(item).addClass('appeared');
                    PageAppear.appear(items);
                }, 125);
            }
            else {
                PageAppear.appearTextNode(items, item);
            }
            itemFound = true;
        });
    }
    static appearInitialLetter(items, item) {
        new InitalLetter(item, false, () => {
            PageAppear.appear(items);
        });
    }
    static appearTextNode(items, item) {
        new Typewriter(item, () => {
            PageAppear.appear(items);
        });
    }
    static appearImageNode(items, item) {
        if ($(item).hasClass('d-lg-none') && window.innerWidth >= 992 || $(item).hasClass('d-lg-block') && window.innerWidth < 992) {
            $(item).addClass('appeared');
            PageAppear.appear(items);
        }
        else {
            const imageLoader = new Image();
            imageLoader.src = $(item).data('src');
            imageLoader.onload = () => {
                $(item).attr('src', $(item).data('src'));
                new Pixelator(item, () => {
                    PageAppear.appear(items);
                });
            };
        }
    }
    static appearBlock(items, item) {
        $(item).addClass('appeared');
        setTimeout(() => {
            PageAppear.appear(items);
        }, 75);
    }
}
PageAppear.items = [];
PageAppear.itemsIndex = 0;
