var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class ScrollTo extends Component {
    constructor() {
        super('ScrollTo', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            if (location.hash) {
                scrollTo(0, this.getItemTopPosition(location.hash));
            }
            $('#menu a').on('click', (event, item) => {
                var hrefAttribute = item.attr('href');
                if (hrefAttribute.indexOf('#') > 0 && (window.location.toString() == 'https://kanakosawada.com/univers/' || window.location.toString() == 'https://kanakosawada.com/')) {
                    event.preventDefault();
                    var target = hrefAttribute.substr(item.attr('href').indexOf('#'));
                    $.scrollTo(this.getItemTopPosition(target), 1000);
                    const cancelled = $('#button-toggle-menu ').get(0).dispatchEvent(new MouseEvent('click', {
                        view: window,
                        bubbles: true,
                        cancelable: true
                    }));
                }
            });
            $('#button-scroll-to-top').on('click', (event) => {
                event.preventDefault();
                $.scrollTo(0, 1000);
            });
            $(window).on('scroll', () => {
                if (window.pageYOffset > 0) {
                    $('#button-scroll-to-top').addClass('active');
                }
                else {
                    $('#button-scroll-to-top').removeClass('active');
                }
            });
            this.success();
        });
    }
    getItemTopPosition(target) {
        var bodyPadding = parseInt(getComputedStyle($('body').get(0)).paddingTop);
        var rect = $(target).get(0).getBoundingClientRect();
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop - bodyPadding;
    }
}
