var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
export class PageFinder extends Component {
    constructor() {
        super('PageFinder', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('#btn-toggle-page-finder').on('click', (event, button) => {
                event.preventDefault();
                button.toggleClass('active');
                $('#page-finder > div').toggleClass('d-flex d-none');
            });
            $('#page-finder input').on('keyup', (event) => {
                if (event.key == 'Enter')
                    this.search();
            });
            $('#btn-find-page').on('click', (event) => {
                event.preventDefault();
                this.search();
            });
            this.success();
        });
    }
    search() {
        return __awaiter(this, void 0, void 0, function* () {
            $('#page-finder').addClass('is-loading');
            const bookCode = $('#page-finder .selected').data('value');
            const bookName = $('#page-finder .selected').text();
            const pageNumber = $('#page-finder input').val();
            const response = yield $.httpRequest({
                url: '/wp-admin/admin-ajax.php',
                type: 'POST',
                body: {
                    action: 'get_page_latlng',
                    book_code: bookCode,
                    page_number: pageNumber
                },
                dataType: 'json'
            });
            if (!response.found) {
                alert('La page ' + pageNumber + ' n’existe pas dans le livre «' + bookName + '».');
            }
            else {
                const event = new CustomEvent('map::zoomTo', {
                    detail: {
                        latlng: {
                            lat: response.lat,
                            lng: response.lng
                        },
                        zoom: response.zoom
                    }
                });
                $('body').get(0).dispatchEvent(event);
            }
            $('#page-finder').removeClass('is-loading');
        });
    }
}
