var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { $ } from 'hanako-ts/dist-legacy/Framework';
import { Component } from 'hanako-ts/dist-legacy/Component';
import lightGallery from 'lightgallery';
export class Gallery extends Component {
    constructor() {
        super('Gallery', false);
    }
    init() {
        const _super = Object.create(null, {
            init: { get: () => super.init }
        });
        return __awaiter(this, void 0, void 0, function* () {
            yield _super.init.call(this);
            $('.gallery-container').each((gallery) => {
                console.log('a');
                lightGallery(gallery.get(0), {
                    selector: '.ratio',
                    counter: true,
                    download: true,
                    mode: 'lg-fade',
                    enableDrag: false,
                    licenseKey: '50D5F6D5-C8A04CC9-B91F3F10-F85C3707'
                });
            });
            this.success();
        });
    }
}
