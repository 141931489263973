import { Analytics } from '../../views/ts/components/Analytics';
import { Menu } from '../../views/twig/components/Menu';
import { PageAppear } from '../twig/components/PageAppear';
import { PageFinder } from '../twig/components/PageFinder';
import { DropdownComponent } from './components/Dropdown';
import { ScrollTo } from './components/ScrollTo';
import { Toggler } from '../twig/components/Toggler';
import { Gallery } from '../twig/pages/template-gallery';
(new Analytics()).init();
(new Menu()).init();
(new PageAppear).init();
(new PageFinder).init();
(new ScrollTo).init();
(new Toggler).init();
(new ScrollTo()).init();
(new DropdownComponent).init();
(new Gallery).init();
